.wp-block-cover,
.wp-block-cover-image {
    position: relative;
    background-size: cover;
    background-position: 50%;
    min-height: 430px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    box-sizing: border-box;

    &.has-parallax {
        background-attachment: fixed;
    }

    &.is-repeated {
        background-repeat: repeat;
        background-size: auto;
    }

    &.has-background-dim {
        &:not([class*="-background-color"]) {
            background-color: var(--color-black);
        }

        &:before {
            content: "";
            background-color: inherit;
        }

        &.has-background-dim-10 {
            .wp-block-cover__background {
                opacity: 0.1;
            }
            .wp-block-cover__gradient-background {
                opacity: 0.1;
            }
            &:not(.has-background-gradient):before {
                opacity: 0.1;
            }
        }

        &.has-background-dim-20 {
            .wp-block-cover__background {
                opacity: 0.2;
            }
            .wp-block-cover__gradient-background {
                opacity: 0.2;
            }
            &:not(.has-background-gradient):before {
                opacity: 0.2;
            }
        }

        &.has-background-dim-30 {
            .wp-block-cover__background {
                opacity: 0.3;
            }
            .wp-block-cover__gradient-background {
                opacity: 0.3;
            }
            &:not(.has-background-gradient):before {
                opacity: 0.3;
            }
        }

        &.has-background-dim-40 {
            .wp-block-cover__background {
                opacity: 0.4;
            }
            .wp-block-cover__gradient-background {
                opacity: 0.4;
            }
            &:not(.has-background-gradient):before {
                opacity: 0.4;
            }
        }

        &.has-background-dim-50 {
            .wp-block-cover__background {
                opacity: 0.5;
            }
            .wp-block-cover__gradient-background {
                opacity: 0.5;
            }
            &:not(.has-background-gradient):before {
                opacity: 0.5;
            }
        }

        &.has-background-dim-60 {
            .wp-block-cover__background {
                opacity: 0.6;
            }
            .wp-block-cover__gradient-background {
                opacity: 0.6;
            }
            &:not(.has-background-gradient):before {
                opacity: 0.6;
            }
        }

        &.has-background-dim-70 {
            .wp-block-cover__background {
                opacity: 0.7;
            }
            .wp-block-cover__gradient-background {
                opacity: 0.7;
            }
            &:not(.has-background-gradient):before {
                opacity: 0.7;
            }
        }

        &.has-background-dim-80 {
            .wp-block-cover__background {
                opacity: 0.8;
            }
            .wp-block-cover__gradient-background {
                opacity: 0.8;
            }
            &:not(.has-background-gradient):before {
                opacity: 0.8;
            }
        }

        &.has-background-dim-90 {
            .wp-block-cover__background {
                opacity: 0.9;
            }
            .wp-block-cover__gradient-background {
                opacity: 0.9;
            }
            &:not(.has-background-gradient):before {
                opacity: 0.9;
            }
        }

        &.has-background-dim-100 {
            .wp-block-cover__background {
                opacity: 1;
            }
            .wp-block-cover__gradient-background {
                opacity: 1;
            }
            &:not(.has-background-gradient):before {
                opacity: 1;
            }
        }

        &:not(.has-background-gradient):before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            opacity: 0.5;
        }
    }

    .has-background-dim {
        &:not([class*="-background-color"]) {
            background-color: var(--color-black);
        }
        &.has-background-gradient {
            background-color: transparent;
        }
    }

    .wp-block-cover__background,
    .wp-block-cover__gradient-background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        opacity: 0.5;

        &.has-background-dim {
            &.has-background-dim-0 {
                opacity: 0;
            }

            &.has-background-dim-10 {
                opacity: 0.1;
            }

            &.has-background-dim-20 {
                opacity: 0.2;
            }

            &.has-background-dim-30 {
                opacity: 0.3;
            }

            &.has-background-dim-40 {
                opacity: 0.4;
            }

            &.has-background-dim-50 {
                opacity: 0.5;
            }

            &.has-background-dim-60 {
                opacity: 0.6;
            }

            &.has-background-dim-70 {
                opacity: 0.7;
            }

            &.has-background-dim-80 {
                opacity: 0.8;
            }

            &.has-background-dim-90 {
                opacity: 0.9;
            }

            &.has-background-dim-100 {
                opacity: 1;
            }
        }
    }

    .wp-block-cover__inner-container {
        width: calc(100% - 40pc);
        padding: 20px 0;
        width: calc(100% - 80px);
        width: 100%;
        z-index: 1;
        color: var(--color-white);
    }

    .wp-block-cover-text,
    .wp-block-cover-image-text {
        font-size: 2em;
        line-height: 1.25;
        z-index: 1;
        margin-bottom: 0;
        max-width: 840px;
        padding: 0.44em;
        text-align: center;
    }

    .wp-block-embed {
        min-width: 320px;
        min-height: 240px;
    }

    .block-library-cover__padding-visualizer {
        z-index: 2;
    }

    &.alignleft {
        max-width: 420px;
        width: 100%;
        display: flex;
    }

    &.alignright {
        max-width: 420px;
        width: 100%;
        display: flex;
    }

    &.aligncenter {
        display: flex;
    }

    &:after {
        display: block;
        content: "";
        font-size: 0;
        min-height: inherit;
    }

    &.is-position-top-left {
        align-items: flex-start;
        justify-content: flex-start;
    }

    &.is-position-top-center {
        align-items: flex-start;
        justify-content: center;
    }

    &.is-position-top-right {
        align-items: flex-start;
        justify-content: flex-end;
    }

    &.is-position-center-left {
        align-items: center;
        justify-content: flex-start;
    }

    &.is-position-center-center {
        align-items: center;
        justify-content: center;
    }

    &.is-position-center-right {
        align-items: center;
        justify-content: flex-end;
    }

    &.is-position-bottom-left {
        align-items: flex-end;
        justify-content: flex-start;
    }

    &.is-position-bottom-center {
        align-items: flex-end;
        justify-content: center;
    }

    &.is-position-bottom-right {
        align-items: flex-end;
        justify-content: flex-end;
    }

    &.has-custom-content-position.has-custom-content-position .wp-block-cover__inner-container {
        margin: 0;
        width: auto;
    }

    .wp-block-cover__image-background,
    .wp-block-cover__video-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-fit: cover;
        outline: none;
        border: none;
        box-shadow: none;
        z-index: 0;
    }

    &.has-left-content {
        .wp-block-cover-text,
        .wp-block-cover-image-text {
            margin-left: 0;
            text-align: left;
        }
    }

    &.has-right-content {
        .wp-block-cover-text,
        .wp-block-cover-image-text {
            margin-right: 0;
            text-align: right;
        }
    }
}

@supports (-webkit-touch-callout: inherit) {
    .wp-block-cover-image.has-parallax {
        background-attachment: scroll;
    }
    .wp-block-cover.has-parallax {
        background-attachment: scroll;
    }
}

@media (prefers-reduced-motion: reduce) {
    .wp-block-cover-image.has-parallax {
        background-attachment: scroll;
    }
    .wp-block-cover.has-parallax {
        background-attachment: scroll;
    }
}

@supports (position: sticky) {
    .wp-block-cover-image:after {
        content: none;
    }
    .wp-block-cover:after {
        content: none;
    }
}
