@import "../../styles/mixins";

$loader-size: 100px;

@include wrapperRoot {
    .wpRoot {
        position: relative;
        display: block;

        &.loading {
            cursor: progress;
        }
    }
}

@keyframes element-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
