@import "../variables";

@mixin breakpoint-only($name) {
    @if $name == mobile {
        @media #{$breakpoint-only-mobile} {
            @content;
        }
    }
    @if $name == mobile-landscape {
        @media #{$breakpoint-only-mobile-landscape} {
            @content;
        }
    }
    @if $name == tablet {
        @media #{$breakpoint-only-tablet} {
            @content;
        }
    }
    @if $name == desktop {
        @media #{$breakpoint-only-desktop} {
            @content;
        }
    }
    @if $name == widescreen {
        @media #{$breakpoint-only-widescreen} {
            @content;
        }
    }
    @if $name == super-widescreen {
        @media #{$breakpoint-only-super-widescreen} {
            @content;
        }
    }
}

@mixin breakpoint-up($name) {
    @if $name == mobile {
        @media #{$breakpoint-up-mobile} {
            @content;
        }
    }
    @if $name == mobile-landscape {
        @media #{$breakpoint-up-mobile-landscape} {
            @content;
        }
    }
    @if $name == tablet {
        @media #{$breakpoint-up-tablet} {
            @content;
        }
    }
    @if $name == desktop {
        @media #{$breakpoint-up-desktop} {
            @content;
        }
    }
    @if $name == widescreen {
        @media #{$breakpoint-up-widescreen} {
            @content;
        }
    }
    @if $name == super-widescreen {
        @media #{$breakpoint-up-super-widescreen} {
            @content;
        }
    }
}
