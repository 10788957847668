@mixin keyframes($animation) {
    @if $animation == element-text {
        @keyframes element-text {
            0% {
                content: "\00a0\00a0\00a0";
            }
            33% {
                content: ".\00a0\00a0";
            }
            66% {
                content: "..\00a0";
            }
            100% {
                content: "...";
            }
        }

        @-webkit-keyframes element-text {
            0% {
                -webkit-content: "\00a0\00a0\00a0";
            }
            33% {
                -webkit-content: ".\00a0\00a0";
            }
            66% {
                -webkit-content: "..\00a0";
            }
            100% {
                -webkit-content: "...";
            }
        }
    }
}
