@import "../variables";

@mixin touch-enabled {
    @media #{$touch-enabled} {
        @content;
    }
}

@mixin not-touch-enabled() {
    @media (hover: hover) {
        @content;
    }
}
