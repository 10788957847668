@import "./mixins/";

html {
    height: 100%;
    font-size: 62.5%;
}

body {
    background-color: #f5f5f5;
    height: 100%;
    min-height: 100vh;
    font-size: var(--font-size-primary);
    background-repeat: no-repeat;
    background-size: 100% auto;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

* {
    touch-action: pan-y;
    line-height: var(--line-height-primary);
}

#root {
    height: 100%;
}

@include wrapperRoot {
    *:focus {
        outline: none !important;
    }

    h1,
    .has-huge-font-size {
        font-size: var(--h1-size);
        line-height: var(--line-height-h1);
        padding: calc(var(--gutter-vertical) * 2) 0 0;
        margin: 0 0 var(--gutter-vertical);
        font-weight: normal;
    }

    h2,
    .has-larger-font-size {
        font-size: var(--h2-size);
        line-height: var(--line-height-h2);
        padding: var(--gutter-vertical) 0 0;
        margin: 0 0 var(--gutter-vertical);
        font-weight: normal;

        strong {
            font-weight: normal;
        }
    }

    h3,
    .has-large-font-size {
        font-size: var(--h3-size);
        line-height: var(--line-height-h3);
        padding: 0;
        margin: 0 0 var(--gutter-vertical);
        font-weight: normal;

        strong {
            font-weight: normal;
        }
    }

    h4,
    .has-medium-font-size {
        font-size: var(--h4-size);
        line-height: var(--line-height-h4);
        padding: 0;
        margin: 0 0 var(--gutter-vertical);
        font-weight: normal;

        strong {
            font-weight: normal;
        }
    }

    .has-normal-font-size,
    .has-regular-font-size {
        font-size: var(--font-size-primary);
    }

    .has-small-font-size {
        font-size: var(--font-size-x-small);
    }

    hr {
        border-bottom: 1px solid var(--color-silver);
        border-top: 0;
        margin-bottom: var(--gutter-vertical);
        font-size: 1px;
    }

    p,
    blockquote,
    figcaption {
        font-size: var(--font-size-primary);
        padding: 0;
        margin: 0 0 var(--gutter-vertical);
        line-height: 24px;

        a {
            text-decoration: underline;
            color: var(--color-primary-green);

            &:focus,
            &:hover {
                color: var(--color-primary-green-80);
                text-decoration: underline;
            }
        }
    }

    a {
        transition: all #{$fast}s ease;
        color: var(--color-primary-green);
        text-decoration: none;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    ol,
    ul {
        padding: 0;
        margin: 0 0 var(--gutter-vertical) 20px;

        li {
            font-size: var(--font-size-primary);
            padding: 0;
            margin: 0;
            line-height: var(--line-height-primary);
        }
    }

    table {
        margin-bottom: var(--gutter-vertical);
        border-collapse: collapse;

        th,
        tr:first-of-type td {
            background-color: rgba(0, 0, 0, 0.1);
            font-weight: bold;
        }

        th,
        td {
            font-size: var(--font-size-primary);
            line-height: var(--line-height-primary);
            border: 1px solid var(--color-silver);
            padding: 5px 10px;
        }
    }

    pre {
        font-size: var(--font-size-primary);
        margin: 0 0 var(--gutter-vertical);
    }

    button {
        padding: 0;
        margin: 0;
        line-height: var(--line-height-primary);
        padding: 2px 5px;
        border: 1px solid var(--color-silver);
        background-color: #f9f9f9;
        font-size: var(--font-size-primary);
        transition: all #{$fast}s ease;
    }

    figure {
        padding: 0;
        margin: 0;
    }

    input {
        font-size: var(--input-font-size);
    }
}

@include breakpoint-up(tablet) {
    #root {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: flex-start;
        height: 100%;
    }

    @include wrapperRoot {
        * {
            touch-action: auto;
            line-height: var(--line-height-primary);
        }
    }
}
