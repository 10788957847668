@import "styles/mixins";

@include keyframes(element-text);

@include wrapperRoot {
    .form {
        position: relative;
        width: 300px;
        background-color: var(--color-white);
        padding: 15px;
        border-radius: 5px;
        overflow: hidden;

        .intro {
            &.processing {
                &::after {
                    animation: element-text infinite 1s linear;
                    content: "";
                }
            }
        }

        /**
      * Form elements
      */

        .formControl {
        }

        .formGroup {
            margin-bottom: var(--gutter-horizontal-small);
        }

        label {
            display: block;
            margin-bottom: 5px;
        }

        input[type="text"],
        input[type="password"],
        select {
            width: 100%;
            padding: 12px 20px;
            margin: 0 0 8px 0;
            display: inline-block;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
        }

        button {
            margin-right: var(--gutter-horizontal);
        }

        /**
      * Processing
      */

        .loaderText {
            font-size: 2rem;
            text-align: center;
            padding: var(--gutter-horizontal) 0 0 0;
            margin: 0;

            &::after {
                animation: element-text infinite 1s linear;
                content: "\00a0\00a0\00a0";
                -webkit-animation: element-text infinite 1s linear;
                -webkit-content: "\00a0\00a0\00a0";
            }
        }

        .loaderGraphic {
            margin: auto;
            display: block;
        }
    }
}
