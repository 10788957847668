@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-rss {
        list-style: none;
        padding: 0;
        margin: 0 0 var(--gutter-vertical);

        li {
            .max-width {
                padding-left: 0;
            }
            a {
                color: #aaa;

                &::before {
                    content: "\f143";
                    font-family: "FontAwesome";
                    font-weight: 400;
                    display: inline-block;
                    margin-right: 5px;
                    vertical-align: top;
                    font-size: var(--font-size-medium);
                    color: #ee802f;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: var(--color-black);
                }
            }
        }

        &.is-grid {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            list-style: none;

            li {
                margin: 0 16px 16px 0;
                width: 100%;
            }
        }

        &__item-author {
            display: block;
            color: #6c7781;
            font-size: var(--font-size-primary);
        }

        &__item-publish-date {
            display: block;
            color: #6c7781;
            font-size: var(--font-size-primary);
        }
    }

    @include breakpoint-up(tablet) {
        .wp-block-rss {
            &.columns-2 li {
                width: calc(50% - 16px);
            }

            &.columns-3 li {
                width: calc(33.33333% - 16px);
            }

            &.columns-4 li {
                width: calc(25% - 16px);
            }

            &.columns-5 li {
                width: calc(20% - 16px);
            }

            &.columns-6 li {
                width: calc(16.66667% - 16px);
            }
        }
    }
}
