@import "styles/mixins";

/**
 * Global styles
 */

%alerts_default {
    position: relative;
    display: block;
    padding: 5px 5px 5px 35px;
    margin: 0 0 var(--gutter-vertical) 0;
    background-repeat: no-repeat;
    background-position: 10px 10px;
    border-radius: 3px;

    &::before {
        font-family: "FontAwesome";
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        font-weight: normal;
        position: absolute;
        left: 5px;
        top: 5px;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        text-align: center;
        line-height: 20px;
    }
}

@include wrapperRoot {
    /**
 * Error message
 */

    .error-alert {
        @extend %alerts_default;
        background-color: #ffe0d9;
        border: 1px solid #bf3313;
        color: #bf3313;

        &::before {
            color: #bf3313;
            border: 2px solid #bf3313;
            content: "\f00d";
        }

        &.dark {
            background-color: rgba(191, 51, 19, 0.3);
            border: 1px solid #ff6d4d;
            color: #ff6d4d;

            &::before {
                color: #ff6d4d;
                border: 2px solid #ff6d4d;
            }
        }
    }

    /**
 * Confirmation
 */

    .confirm-alert {
        @extend %alerts_default;
        background-color: #d7fce9;
        border: 1px solid #0f944f;
        color: #0f944f;

        &::before {
            color: #0f944f;
            border: 2px solid #0f944f;
            content: "\f00c";
        }

        &.dark {
            background-color: rgba(15, 148, 79, 0.3);
            border: 1px solid #19ff88;
            color: #19ff88;

            &::before {
                color: #19ff88;
                border: 2px solid #19ff88;
            }
        }
    }

    /**
 * Info
 */

    .info-alert {
        @extend %alerts_default;
        background-color: #c7ddff;
        border: 1px solid #1358bf;
        color: #1358bf;

        &::before {
            color: #1358bf;
            border: 2px solid #1358bf;
            content: "\f129"; //f05a
        }

        &.dark {
            background-color: rgba(19, 88, 191, 0.3);
            border: 1px solid #4d94ff;
            color: #4d94ff;

            &::before {
                color: #4d94ff;
                border: 2px solid #4d94ff;
            }
        }
    }

    /**
 * Warning
 */

    .warning-alert {
        @extend %alerts_default;
        background-color: #fff7d9;
        border: 1px solid #b38c00;
        color: #b38c00;

        &::before {
            color: #b38c00;
            border: 2px solid #b38c00;
            content: "\f12a";
        }

        &.dark {
            background-color: rgba(179, 140, 0, 0.3);
            border: 1px solid #ffd84d;
            color: #ffd84d;

            &::before {
                color: #ffd84d;
                border: 2px solid #ffd84d;
            }
        }
    }
}
