@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-pullquote {
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
        margin-bottom: var(--gutter-vertical);
        font-style: italic;

        blockquote {
            margin-bottom: 0;
        }

        p {
            font-size: var(--font-size-large);
            margin-bottom: calc(var(--gutter-vertical) / 2);
        }

        footer {
            position: relative;
        }

        cite {
            color: rgba(0, 0, 0, 0.7);
            font-size: var(--font-size-primary);
        }
    }
}
