@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-verse {
        white-space: normal;
        border: 1px solid var(--color-grey);
        padding: 10px;
        margin-bottom: var(--guttering-vertical);
        border-radius: 3px;
    }
}
