@import "../../mixins/";

@include wrapperRoot {
    --main-bg: transparent;

    --color-black-10: rgba(0, 0, 0, 0.1);
    --color-black-20: rgba(0, 0, 0, 0.2);
    --color-black-50: rgba(0, 0, 0, 0.5);
    --color-black-70: rgba(0, 0, 0, 0.7);
    --color-black-90: #00000090;
    --color-black: #000000;
    --color-grey-dark: #777777;
    --color-grey-darker: #555555;
    --color-grey-darkest: #222222;
    --color-grey-lightest: #ebebeb;
    --color-grey: #999999;
    --color-lightgrey: lightgrey;
    --color-orange: orange;
    --color-primary-green: #005500;
    --color-primary-green-80: #00550080;
    --color-primary-green-90: #00550090;
    --color-silver: silver;
    --color-white: #ffffff;

    @include breakpoint-up(desktop) {
        --main-bg: #f9f9f9;
    }
}
