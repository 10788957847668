@import "../../../styles/mixins/";
// @import "./WPBlockGallery.default";

$columns1: 100%;
$columns2: calc((100% - 15px) / 2);
$columns3: calc((100% - 30px) / 3);
$columns4: calc((100% - 45px) / 4);
$columns5: calc((100% - 60px) / 5);
$columns6: calc((100% - 75px) / 6);
$columns7: calc((100% - 90px) / 7);
$columns8: calc((100% - 105px) / 8);
$columns9: calc((100% - 120px) / 9);

@include wrapperRoot {
    .wp-block-gallery {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        list-style: none;
        gap: var(--gutter-vertical) var(--gutter-horizontal) !important; // wp-editor
        margin-bottom: var(--gutter-vertical);

        > figure,
        .wp-block-image {
            display: block;
            width: $columns1;
            margin-bottom: 0;

            img {
                width: 100%;
                height: auto;
                max-width: none;
                border-radius: 3px;
                display: block;
                margin-bottom: 0;
            }
        }

        .blocks-gallery-caption {
            display: block;
            text-align: center;
            margin-bottom: var(--gutter-vertical);
            font-style: italic;
            width: 100%;
        }

        &.columns-2 > figure,
        &.columns-3 > figure {
            max-width: $columns1;
        }

        &.columns-4 > figure {
            max-width: $columns2;
        }

        &.columns-5 > figure,
        &.columns-6 > figure {
            max-width: $columns3;
        }

        &.columns-7 > figure,
        &.columns-8 > figure,
        &.columns-9 > figure {
            max-width: $columns4;
        }
    }

    @include breakpoint-up(mobile-landscape) {
        .wp-block-gallery {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 var(--gutter-vertical) 0;
            list-style: none;

            > figure,
            .wp-block-image {
                display: block;
                width: 100%;

                .blocks-gallery-caption {
                    text-align: center;
                    margin-bottom: var(--gutter-vertical);
                    font-style: italic;
                }
            }

            &.columns-2 > figure {
                max-width: $columns2;
            }

            &.columns-3 > figure {
                max-width: $columns3;
            }

            &.columns-4 > figure {
                max-width: $columns4;
            }

            &.columns-5 > figure {
                max-width: $columns5;
            }

            &.columns-6 > figure {
                max-width: $columns6;
            }

            &.columns-7 > figure {
                max-width: $columns7;

                figcaption {
                    font-size: var(--font-size-x-small);
                }
            }

            &.columns-8 > figure {
                max-width: $columns8;

                figcaption {
                    font-size: var(--font-size-x-small);
                }
            }

            &.columns-9 > figure {
                max-width: $columns9;

                figcaption {
                    font-size: var(--font-size-x-small);
                }
            }
        }
    }

    @include breakpoint-up(tablet) {
        .wp-block-gallery {
            > figure,
            .wp-block-image {
                flex-grow: 1;
                min-width: 0;
                word-break: break-word;
                overflow-wrap: break-word;
                flex-basis: 100%;

                figcaption {
                    position: relative;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 5px 10px;
                    color: var(--color-white);
                    background-color: var(--color-primary-green);
                    a {
                        &,
                        &:hover,
                        &:focus {
                            display: block;
                            color: var(--color-white);
                            margin: -5px -10px;
                            padding: 3px 10px;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
