@import "styles/mixins";

@include wrapperRoot {
    .tabbedMenu {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: var(--max-width);
        margin: auto;
        height: var(--tabbed-menu-height);
        padding-left: var(--gutter-horizontal);

        ol,
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            border: 0;

            li a {
                display: block;
                border: 0;
                padding: 0;
                margin: 0;
                text-decoration: none;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }
        }

        .nav {
            display: block;

            > li {
                position: relative;
                display: inline-block;
                margin: 0 var(--gutter-horizontal) 0 0;

                &:hover,
                &:focus {
                    > a::after {
                        left: 0;
                        width: 100%;
                    }
                }

                &.subNavActive {
                    > ul {
                        transform: scaleY(1);
                    }
                }

                &.right {
                    float: right;
                    margin-right: 0;
                    margin-left: var(--gutter-horizontal);
                }

                > a {
                    position: relative;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 var(--gutter-horizontal);
                    border: 0;
                    transition: all #{$fast}s ease;
                    color: var(--color-grey-darker);
                    font-weight: normal;
                    text-transform: uppercase;

                    &::after {
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        width: 0;
                        height: 2px;
                        background-color: var(--color-primary-green);
                        content: "";
                        transition: all #{$fast}s ease;
                    }

                    &.active {
                        color: var(--color-black);

                        &::after {
                            left: 0;
                            width: 100%;
                        }
                    }

                    &:hover,
                    &:focus {
                        background-color: transparent;
                        text-decoration: none;

                        &::after {
                            left: 0;
                            width: 100%;
                        }
                    }
                }

                // Subnav

                > ul {
                    position: absolute;
                    left: 0;
                    top: 30px;
                    background-color: #eee;
                    padding: 0;
                    transform: scaleY(0);
                    transform-origin: 0 0;
                    transition: all #{$fast}s ease;
                    max-height: calc(30px * 14);
                    overflow-y: auto;

                    > li {
                        display: block;

                        > a {
                            display: block;
                            height: 30px;
                            line-height: 30px;
                            padding: 0 11px;
                            white-space: nowrap;
                            color: var(--color-grey-darker);

                            &.active {
                                color: var(--color-black);
                                background-color: rgba(0, 0, 0, 0.2);
                            }

                            &:hover,
                            &:focus {
                                background-color: rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                }
            }
        }

        &.primaryCover {
            color: var(--color-white);
            background-color: transparent;

            .nav {
                > li {
                    > a {
                        background-color: transparent;
                        color: rgba(255, 255, 255, 0.8);

                        &::after {
                            background-color: var(--color-white);
                        }

                        &.active {
                            color: var(--color-white);
                        }

                        &:hover,
                        &:focus {
                            color: rgba(255, 255, 255, 0.8);
                        }
                    }

                    // Subnav

                    > ul {
                        background-color: var(--color-black-90);

                        > li {
                            > a {
                                color: var(--color-white);

                                &.active {
                                    color: var(--color-white);
                                    background-color: rgba(255, 255, 255, 0.4);
                                }

                                &:hover,
                                &:focus {
                                    color: var(--color-white);
                                    background-color: rgba(255, 255, 255, 0.2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
