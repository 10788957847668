@import "../mixins/";

@include wrapperRoot {
    .has-text-align-center {
        text-align: center;
    }

    .has-text-align-left {
        text-align: left;
    }

    .has-text-align-right {
        text-align: right;
    }

    .items-justified-left {
        justify-content: flex-start;
    }

    .items-justified-center {
        justify-content: center;
    }

    .items-justified-right {
        justify-content: flex-end;
    }

    .items-justified-space-between {
        justify-content: space-between;
    }

    @include breakpoint-up(desktop) {
        .has-text-align-left {
            float: none !important;
            margin-right: 30%;
        }

        .has-text-align-right {
            float: none !important;
            margin-left: 30%;
        }

        .has-text-align-center {
            float: none !important;
            margin-left: 15%;
            margin-right: 15%;
        }

        .alignleft,
        [data-align="left"] {
            clear: none !important;
            float: left !important;
            width: 40% !important;
            margin-right: var(--gutter-horizontal);
        }

        .alignright,
        [data-align="right"] {
            clear: none !important;
            float: right !important;
            width: 40% !important;
            margin-left: var(--gutter-horizontal);
        }

        .aligncenter,
        [data-align="center"] {
            clear: both !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .alignwide,
        [data-align="wide"] {
            clear: both !important;
        }

        .alignfull {
            clear: both;
            width: 100%;
        }

        [data-align="left"],
        [data-align="right"],
        [data-align="center"],
        [data-align="wide"] {
            & > * {
                width: 100%;
            }
        }
    }
}
