@import "styles/mixins";

@include wrapperRoot {
    .modalWrapper {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        backdrop-filter: blur(0);
        transition: backdrop-filter #{$modal-animation}s ease-in-out;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: var(--color-grey-darkest);
            opacity: 0;
            z-index: 1000;
        }

        &.modalOpening,
        &.modalClosing {
            display: block;
        }

        &.modalOpening {
            backdrop-filter: blur(2px);

            &::before {
                opacity: $modal-fade-opacity;
                animation: fadeIn #{$modal-animation}s ease-in-out;
            }
        }

        &.modalClosing {
            &::before {
                animation: fadeOut #{$modal-animation}s ease-in-out;
            }
        }
    }

    .modal {
        display: flex;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1001;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }

    .modalContent {
        display: block;
        position: relative;
        margin: auto;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        background-color: var(--color-white);
        transform: scale(75%);
        opacity: 0;
        z-index: 1002;
        font-family: Roboto, sans-serif;
        overflow: hidden;
        filter: blur(8px);
        transition: filter #{$initial-loader-animation}s ease-in-out;
        max-width: var(--max-width);

        .close {
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 16px;
            width: 32px;
            height: 32px;
            padding: 0;
            border-radius: 50%;
            overflow: hidden;
            text-indent: -100px;
            background-color: var(--color-white);
            border: 0;
            margin: 0;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
            z-index: 5;

            &:focus {
                outline: 1px dotted var(--color-grey);
                outline-offset: 2px;
            }

            &::after {
                position: absolute;
                left: 8px;
                top: 8px;
                width: 16px;
                height: 16px;
                content: "";
                background-image: url("/assets/img/xclose.svg");
                background-repeat: no-repeat;
                background-size: cover;
            }

            &:hover,
            &:focus {
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
            }

            &:disabled {
                pointer-events: none;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
                opacity: 0.5;
            }
        }

        &.modalContentOpening {
            filter: blur(0);
            transform: scale(100%);
            opacity: 1;
            animation: scaleIn #{$modal-animation}s ease-in-out;
        }

        &.modalContentClosing {
            animation: scaleOut #{$modal-animation}s ease-in-out;
        }

        &.media {
            border-radius: 2px;
            padding: 30px;
            background-color: transparent;
            box-shadow: none;

            img,
            video {
                display: block;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
            }

            .close {
                display: none;
            }
        }
    }

    @include breakpoint-up(mobile-landscape) {
        .modalContent {
            .close {
                width: 24px;
                height: 24px;

                &::after {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }

    @include breakpoint-up(tablet) {
        .modalContent {
            &.media {
                .close {
                    display: block;
                    right: 4px;
                    top: 4px;
                }
            }
        }
    }
}

/**
 * Keyframes
 */

@keyframes scaleIn {
    0% {
        transform: scale(75%);
        opacity: 0;
    }
    100% {
        transform: scale(100%);
        opacity: 1;
    }
}

@keyframes scaleOut {
    0% {
        transform: scale(100%);
        opacity: 1;
    }
    100% {
        transform: scale(75%);
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: $modal-fade-opacity;
    }
}

@keyframes fadeOut {
    0% {
        opacity: $modal-fade-opacity;
    }
    100% {
        opacity: 0;
    }
}
