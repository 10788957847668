@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-video {
        video {
            width: 100%;
            height: auto;
            margin-bottom: var(--gutter-vertical);
        }

        figcaption {
            margin-top: calc((var(--gutter-vertical) / 2) * -1);
            margin-bottom: var(--gutter-vertical);
            font-style: italic;
            text-align: center;
        }
    }

    @supports (position: sticky) {
        .wp-block-video [poster] {
            -o-object-fit: cover;
            object-fit: cover;
        }
    }
}
