@import "styles/mixins";

@include wrapperRoot {
    .slideInMenu {
        position: fixed;
        left: 0;
        top: 0;
        padding: 0;
        z-index: 100;

        ol,
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            border: 0;

            li a {
                display: block;
                border: 0;
                padding: 0;
                margin: 0;
                text-decoration: none;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }
        }

        .welcome {
            padding: 10px var(--gutter-horizontal);
            background-color: var(--color-white);
            color: var(--color-black);
        }

        .toggle {
            position: fixed;
            top: 0;
            right: 0;
            border: 0;
            height: 70px;
            padding: 0;
            width: 60px;
        }

        .menuContainer {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            top: 70px;
            z-index: 100;
            background-color: #ccc;
            transition: all #{$fast}s ease-out;
            overflow: auto;
            transform: translateX(-100%);
        }

        .nav {
            border: 1px solid #00550050;
            border-bottom: 0;
            border-left: 0;
            padding: 0;
            margin: 0;

            > li {
                > a {
                    height: 50px;
                    line-height: 50px;
                    padding: 0 0 0 var(--gutter-horizontal);
                    background-color: #eee;
                    border-bottom: 1px solid var(--color-silver);
                    text-decoration: none;

                    &.active {
                        background-color: #efefef;
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    &:focus,
                    &:hover {
                        background-color: #efefef;
                        text-decoration: none;
                    }
                }

                a {
                    display: block;
                    color: var(--color-grey-darkest);
                    text-decoration: none;

                    &.active {
                        font-weight: bold;
                        color: var(--color-black);
                        text-decoration: none;
                    }

                    &:focus,
                    &:hover {
                        text-decoration: none;

                        &.active {
                            color: var(--color-black);
                        }
                    }
                }

                // Subnav styles

                > ul {
                    background-color: #ddd;
                    border-bottom: 1px solid var(--color-silver);
                    min-width: 100%;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    > li {
                        > a {
                            height: 40px;
                            line-height: 40px;
                            padding: 0 0 0 var(--gutter-horizontal);

                            &:focus,
                            &:hover {
                                color: var(--color-grey);
                            }
                        }
                    }
                }
            }
        }

        &.open {
            right: 0;
            bottom: 0;

            .toggle {
                .icon-bar {
                    opacity: 0;

                    &:first-child {
                        opacity: 1;
                        width: 30px;
                        transform: rotate(45deg);
                        left: -4px;
                        top: 9px;
                    }

                    &:last-child {
                        opacity: 1;
                        width: 30px;
                        transform: rotate(-45deg);
                        left: -4px;
                        top: -9px;
                    }
                }
            }

            .menuContainer {
                transform: translateX(0);
            }
        }
    }

    @include breakpoint-up(mobile-landscape) {
        .slideInMenu {
            padding-bottom: 0;

            .menuContainer {
                left: -375px;
                width: 375px;
            }

            &.open {
                .menuContainer {
                    left: 0;
                }
            }
        }
    }

    @include breakpoint-up(tablet) {
        .slideInMenu {
            .toggle {
                height: 80px;
            }

            .menuContainer {
                top: 80px;
            }
        }
    }
}
