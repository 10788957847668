@import "styles/mixins/";

@include wrapperRoot {
    .footer {
        background-color: var(--color-grey-darkest);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        height: var(--footer-height);
        overflow: hidden;

        footer {
            position: relative;
            margin: auto;
            color: rgba(255, 255, 255, 0.8);
            padding: 10px var(--gutter-horizontal);
            display: flex;

            .address {
                flex: 1 1 auto;
                width: calc(100% - 82px);
                text-align: left;
                vertical-align: middle;
                margin: 0;
                padding: 0;

                a {
                    color: rgba(255, 255, 255, 0.8);
                }
            }

            .social {
                flex: 1 0 auto;
                vertical-align: middle;
                margin-bottom: 0;
                padding-left: var(--gutter-horizontal);

                .socialText {
                    display: block;
                    font-style: italic;
                    padding-bottom: 5px;
                }
            }
        }
    }

    @include breakpoint-up(tablet) {
        .footer {
            position: relative;
            order: 0;
            flex: 0 1 var(--footer-height);
            align-self: stretch;
        }
    }

    @include breakpoint-up(desktop) {
        .footer {
            padding-right: 0;

            footer {
                max-width: var(--max-width);
            }
        }

        @include touch-enabled() {
            .unpinned-footer {
                footer {
                    padding-right: var(--scrollbar-size);
                }
            }
        }
    }
}
