@import "styles/mixins/";

.main {
    position: relative;
    padding-top: var(--header-height);
    min-height: calc(100% - var(--header-height));

    main {
        margin: auto;
        color: var(--color-grey-darkest);
        background-color: var(--main-bg);
        padding: 0 0 calc(20px + var(--footer-height));
        min-height: calc(100% - var(--footer-height));
    }
}

.max-width,
.default-max-width {
    max-width: var(--max-width);
    padding: 0 var(--gutter-horizontal);
    margin: auto;
    display: block;
    // width: 100%;

    &.hidden {
        padding: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
}

@include breakpoint-up(tablet) {
    .main {
        order: 0;
        flex: 1 1 auto;
        align-self: stretch;
        min-height: 0;

        main {
            padding: 0 0 20px;
            min-height: calc(100% - var(--footer-height) - 20px);
        }
    }
}

@include breakpoint-up(desktop) {
    .main {
        position: static;
        padding-top: 0;

        main {
            background-color: var(--main-bg);
            padding-bottom: 40px;
            min-height: calc(100% - var(--footer-height) - 40px);
        }
    }
}
