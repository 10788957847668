@import "../../mixins/";

@include wrapperRoot {
    --footer-height: 116px;
    --gutter-horizontal: 10px !important;
    --gutter-horizontal-small: 5px !important;
    --gutter-vertical: 15px !important;
    --header-height: 70px;
    --max-width: 1200px;
    --main-bg: transparent;
    --scrollbar-size: 8px;
    --tabbed-menu-height: 0px;

    @include breakpoint-up(mobile-landscape) {
        --footer-height: 84px;
    }

    @include breakpoint-up(tablet) {
        --gutter-horizontal: 15px !important;
        --gutter-horizontal-small: 10px !important;
        --gutter-vertical: 20px !important;
        --header-height: 80px;
    }

    @include breakpoint-up(desktop) {
        --gutter-horizontal: 20px;
        --header-height: 115px;
        --main-bg: #f9f9f9;
        --tabbed-menu-height: 30px;

        @include touch-enabled() {
            --gutter-horizontal: 20px;
            --header-height: 80px; // non touch enabled --header-height minus --tabbed-menu-height
        }
    }
}
