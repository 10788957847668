@import "styles/mixins";

@include wrapperRoot {
    .userStatus {
        display: block;

        span,
        button {
            display: inline-block;
            vertical-align: middle;
            font-size: 1.2rem;
        }

        span {
            display: none;
            line-height: 1.4rem;
        }

        button {
            margin-left: 10px;
        }
    }

    @include breakpoint-up(tablet) {
        .userStatus {
            span {
                display: inline-block;
            }

            button {
                margin-left: 10px;
            }
        }
    }
}
