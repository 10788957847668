@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-calendar {
        text-align: center;

        th {
            padding: 4px;
            border: 1px solid #e2e4e7;
        }

        tfoot {
            td {
                border: none;
            }

            a {
                color: #00739c;
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;

            th {
                font-weight: 400;
                background: #edeff0;
            }

            caption {
                color: #40464d;
            }

            tbody {
                color: #40464d;
            }
        }

        a {
            text-decoration: underline;
        }

        tbody td {
            padding: 4px;
            border: 1px solid #e2e4e7;
        }
    }
}
