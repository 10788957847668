@import "styles/mixins";

@include wrapperRoot {
    .topMenu {
        display: inline-block;
        margin: 0;
        padding: 0;
        line-height: 1em;

        li {
            display: inline-block;
            font-size: 1.2rem;

            &::after {
                display: inline-block;
                content: "|";
            }

            &:last-of-type {
                a {
                    margin-right: 0;
                }

                &::after {
                    display: none;
                }
            }

            a {
                text-decoration: none;
                margin: 0 8px;
                position: relative;
                padding-bottom: 4px;

                &::after {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    width: 0;
                    height: 2px;
                    background-color: var(--color-primary-green);
                    content: "";
                    transition: all #{$fast}s ease;
                }

                &.active {
                    font-weight: bold;
                    opacity: 1;

                    &::after {
                        left: 0;
                        width: 100%;
                    }
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                    opacity: 1;

                    &::after {
                        left: 0;
                        width: 100%;
                    }
                }
            }
        }

        &.primaryCover {
            li {
                a {
                    color: var(--color-white);

                    &::after {
                        background-color: var(--color-white);
                    }
                }
            }
        }
    }
}
