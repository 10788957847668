@import "../../../styles/mixins/";

%extend_1 {
    border-radius: 24px;
    display: block;
    float: left;
    min-height: 40px;
    margin-right: var(--gutter-horizontal);
    width: 40px;
}

@include wrapperRoot {
    .wp-block-latest-comments {
        .avatar {
            @extend %extend_1;
        }

        &__comment {
            font-size: var(--font-size-primary);
            line-height: 1.1;
            list-style: none;
            margin-bottom: 1em;
        }

        &__comment-date {
            color: #8f98a1;
            display: block;
            font-size: var(--font-size-x-small);
        }

        &__comment-avatar {
            @extend %extend_1;

            p {
                font-size: var(--font-size-primary);
                line-height: 1.8;
                margin: 5px 0 20px;
            }
        }
    }

    .has-avatars .wp-block-latest-comments__comment {
        min-height: 36px;
        list-style: none;

        .wp-block-latest-comments__comment-excerpt {
            margin-left: 52px;
        }

        .wp-block-latest-comments__comment-meta {
            margin-left: 52px;
        }
    }

    .has-dates .wp-block-latest-comments__comment {
        line-height: 1.5;
    }

    .has-excerpts .wp-block-latest-comments__comment {
        line-height: 1.5;
    }
}
