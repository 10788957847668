@import "styles/mixins";

/**
 * VARS
 */

$hamburger-gap: 8px;
$hamburger-height: 60px;
$hamburger-icon-color: var(--color-grey-darkest);
$hamburger-icon-thickness: 2px;
$hamburger-icon-width: 22px;
$hamburger-width: 60px;
$hamburger-transition: all #{$fast}s ease;

/**
  * COMPONENT
  */

@include wrapperRoot {
    .hamburger {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: $hamburger-height;
        padding: 0;
        width: $hamburger-width;
        background-color: transparent;
        margin: auto;
        gap: $hamburger-gap;
        transition: $hamburger-transition;

        span {
            position: relative;
            left: 0;
            top: 0;
            display: block;
            width: $hamburger-icon-width;
            height: $hamburger-icon-thickness;
            opacity: 1;
            transform: rotate(0);
            transition: $hamburger-transition;
            overflow: hidden;
            background-color: $hamburger-icon-color;
        }

        &.active {
            gap: 0;

            span {
                &:nth-child(1) {
                    transform: rotate(45deg) scaleX(1.414) translateY(50%);
                }

                &:nth-child(2) {
                    opacity: 0;
                    height: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg) scaleX(1.414) translateY(-50%);
                }
            }
        }

        &.primaryCover {
            span {
                background-color: var(--color-white);
            }
        }
    }
}
