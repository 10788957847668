@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-tag-cloud {
        margin-bottom: var(--gutter-vertical);

        a {
            display: inline-block;
            background-color: var(--color-silver);
            border: 1px solid var(--color-silver);
            border-radius: 17px;
            padding: 5px 10px;
            margin-right: 8px;
            color: var(--color-black);
            text-decoration: none;

            &:hover {
                background-color: #f0f0f0;
                text-decoration: none;
            }
        }
    }
}
