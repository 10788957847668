@import "styles/mixins";

@include keyframes(element-text);

@include wrapperRoot {
    .loading {
        display: flex;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--color-grey-darkest);
        align-items: center;
        justify-content: center;
        z-index: 20;
        opacity: 1;
        transition: opacity #{$initial-loader-animation}s ease-in-out;

        &.loadingClosing {
            opacity: 0;
            // animation: fadeOut #{$initial-loader-animation}s ease-in-out;
        }

        .content {
            position: relative;
            display: inline-block;
            text-align: center;
            color: var(--color-white);
            filter: blur(0);
            transition: filter #{$initial-loader-animation}s ease-in-out;

            .logo {
                display: inline-block;
                height: 70px;
                vertical-align: top;
                padding: 5px 0;
            }

            p {
                display: inline-block;
                vertical-align: top;
                line-height: 1em;
                margin: 14px 0 0 10px;
                font-weight: bold;
                font-size: var(--font-size-header);
                font-style: italic;
                width: 170px;
            }

            small {
                display: block;
                text-align: center;
                padding-top: 15px;
                font-size: 1.6rem;

                &::after {
                    animation: element-text infinite 1s linear;
                    content: "";
                }
            }

            &.contentClosing {
                filter: blur(8px);
                animation: scaleOut #{$initial-loader-animation}s ease-in-out;
            }
        }
    }

    @include breakpoint-up(tablet) {
        .loading {
            .content {
                p {
                    width: 200px;
                }
            }
        }
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes scaleOut {
    0% {
        transform: scale(100%);
        opacity: 1;
    }
    100% {
        transform: scale(75%);
        opacity: 0;
    }
}
