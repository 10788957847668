@import "../../../styles/mixins/";

$gradient-color: var(--main-bg);

@include wrapperRoot {
    .wp-block-more {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: 0;
        height: 30px;
        line-height: 30px;
        text-align: center;
        padding-top: 8px;

        button {
            position: relative;
            display: inline-block;
            border: 1px solid var(--color-silver);
            background-color: #f9f9f9;
            height: 24px;
            color: var(--color-grey);
            z-index: 1;
            cursor: pointer;

            &:active {
                color: var(--color-grey);
            }
            &:hover,
            &:focus {
                color: var(--color-lightgrey);
            }
        }

        &::after {
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
            height: 1px;
            content: "";
            background-color: var(--color-silver);
        }

        &::before {
            display: none;
        }

        &.closed {
            bottom: calc(var(--gutter-vertical) * 2);
            margin-top: -30px;
            background-color: #f9f9f9;

            &::before {
                position: absolute;
                top: -40px;
                left: 0;
                right: 0;
                content: "";
                display: block;
                height: 40px;
                background: linear-gradient(0deg, #f9f9f9 0%, #f9f9f900 100%);
            }

            & ~ * {
                display: none;
            }
        }
    }
}
