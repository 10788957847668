@import "styles/mixins";

@include wrapperRoot {
    .scrollToTop {
        display: block;
        position: fixed;
        right: var(--gutter-horizontal);
        bottom: calc(var(--footer-height) + var(--gutter-horizontal));
        z-index: 9;
        opacity: 0;
        transition: opacity #{$fast}s ease-in-out;
        visibility: hidden;

        &.visible {
            opacity: 1;
            visibility: visible;
        }

        .button {
            background-color: rgba(255, 255, 255, 0.75);

            &::before {
                content: "\f062";
                font-family: "FontAwesome";
                color: var(--color-primary-green);
            }
        }
    }

    @include breakpoint-up(tablet) {
        .scrollToTop {
            .button {
                &::before {
                    margin-right: 8px;
                }
            }
        }
    }
}
