@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-buttons {
        margin-bottom: var(--gutter-vertical);

        .wp-block-button {
            a {
                display: inline-block;
                margin-right: 8px;
                margin-bottom: 8px;
                font-size: var(--font-size-primary);
                padding: 3px 8px;
                border-radius: 5px;
                background-color: rgba(50, 50, 50, 0.8);
                text-decoration: none;
                color: var(--color-white);

                &:hover,
                &:focus {
                    background-color: rgba(50, 50, 50, 0.5);
                    text-decoration: none;
                }
            }

            &.is-style-outline a {
                padding: 1px 4px;
                background-color: transparent;
                color: var(--color-black);
                border: 2px solid rgba(50, 50, 50, 0.8);

                &:hover,
                &:focus {
                    background-color: rgba(50, 50, 50, 0.1);
                    border: 2px solid rgba(50, 50, 50, 0.5);
                }
            }
        }
    }
}
