@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-audio {
        figcaption {
            margin-top: calc((var(--gutter-vertical)) * -1);
            margin-bottom: var(--gutter-vertical);
            font-style: italic;
            text-align: center;
        }

        audio {
            width: 100%;
            min-width: 300px;
            margin-bottom: var(--gutter-vertical);
        }
    }
}
