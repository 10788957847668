@import "../../../styles/mixins/";
@import "./WPBlockCover.default";

@include wrapperRoot {
    .wp-block-cover {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        min-height: 200px;
        padding: var(--gutter-horizontal);
        display: flex !important;
        align-items: center;
        margin-bottom: var(--gutter-vertical);
        --top-spacer: calc(var(--header-height) + 1px);

        &.primary-cover {
            position: relative;
            top: calc(var(--top-spacer) * -1);
            margin-bottom: calc((var(--top-spacer) * -1) + var(--gutter-vertical));
            padding-top: calc(var(--top-spacer) + var(--gutter-horizontal));
        }

        &__inner-container {
            max-width: var(--max-width);
            margin: auto;
            display: block;
            width: 100%;

            h1,
            h2,
            h3 {
                font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
                line-height: 1em;
            }

            h1 {
                font-size: 30px;
            }

            .max-width {
                padding: 0;
            }

            .wp-block-image img {
                border-radius: 3px;
                box-shadow: 0 0 3px #fff;
            }

            .wp-block-column {
                background-color: rgba(0, 0, 0, 0.5);
                margin-bottom: var(--gutter-horizontal);
                padding: var(--gutter-horizontal);
                border-radius: 3px;
            }

            a {
                color: var(--color-white);

                &:hover,
                &:focus {
                    color: var(--color-white);
                    opacity: 0.6;
                    text-decoration: none;
                }
            }

            p a {
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        .wp-block-cover-text {
            font-size: 2em;
            line-height: 1.25;
            z-index: 1;
            margin-bottom: 0;
            max-width: 1024px;
            padding: 14px;
            text-align: center;
            color: var(--color-white);

            a {
                color: var(--color-white);

                &:active,
                &:focus,
                &:hover {
                    color: var(--color-white);
                }
            }
        }

        &.has-left-content .wp-block-cover-text {
            margin-left: 0;
            text-align: left;
        }

        &.has-right-content .wp-block-cover-text {
            margin-right: 0;
            text-align: right;
        }
    }

    @include breakpoint-up(desktop) {
        .wp-block-cover {
            min-height: 400px;

            h1 {
                font-size: 50px;
            }
        }
    }

    @include breakpoint-up(widescreen) {
        .wp-block-cover {
            min-height: 600px;
        }
    }
}
