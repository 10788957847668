@import "styles/mixins";

@include wrapperRoot {
    .button {
        display: inline-block;
        font-size: var(--font-size-primary);
        padding: 10px 10px;
        border-radius: 6px;
        text-decoration: none;
        border: 0;
        cursor: pointer;
        color: var(--color-white);

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &.primary {
            background-color: #00a800;

            &:hover,
            &:focus {
                background-color: #00a80090;
            }

            &:disabled {
                background-color: #00a800;
            }
        }

        &.secondary {
            background-color: var(--color-primary-green);

            &:hover,
            &:focus {
                background-color: var(--color-primary-green-90);
            }

            &:disabled {
                background-color: var(--color-primary-green);
            }
        }

        &.tertiary {
            background-color: transparent;
            color: var(--color-primary-green);
            border: 1px solid var(--color-primary-green);
            padding: 8px 13px;

            &:hover,
            &:focus {
                color: var(--color-primary-green-90);
                border: 1px solid var(--color-primary-green-90);
            }

            &:disabled {
                color: var(--color-primary-green);
                border: 1px solid var(--color-primary-green);
            }
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    @include breakpoint-up(mobile-landscape) {
        .button {
            padding: 10px 15px;
        }
    }
}
