@import "../../../styles/mixins/";

@include wrapperRoot {
    hr.wp-block-separator {
        margin: 30px 0;
    }

    .wp-block-separator {
        border-top: 1px solid;
        border-bottom: 1px solid;

        &.is-style-wide {
            max-width: calc(100vw - 40px);
            position: relative;
            width: 100%;
        }

        &.is-style-dots::before {
            background: none;
            color: inherit;
            font-size: 32px;
            font-weight: 700;
            height: auto;
            letter-spacing: 1em;
            padding-left: 1em;
            position: static;
            transform: none;
            width: auto;
        }

        &.is-style-dots::after {
            content: none;
        }

        &.is-style-dots {
            background: none !important;
            border: none;
            text-align: center;
            line-height: 1;
            height: auto;

            &:before {
                content: "···";
                color: currentColor;
                font-size: 1.5em;
                letter-spacing: 2em;
                padding-left: 2em;
                font-family: serif;
            }
        }
    }

    @include breakpoint-up(mobile-landscape) {
        hr.wp-block-separator {
            margin: 60px auto;
        }
        .wp-block-separator.is-style-wide {
            max-width: calc(100vw - 80px);
        }
    }

    @include breakpoint-up(desktop) {
        hr.wp-block-separator {
            margin: 80px auto;
        }
    }

    @include breakpoint-up(widescreen) {
        .wp-block-separator.is-style-wide {
            max-width: 1200px;
            width: 1200px;
        }
    }
}
