@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-text-columns {
        display: flex;

        &.aligncenter {
            display: flex;
        }

        .wp-block-column {
            margin: 0 var(--gutter-horizontal);
            padding: 0;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }

        &.columns-2 .wp-block-column {
            width: 50%;
        }

        &.columns-3 .wp-block-column {
            width: 33.33333%;
        }

        &.columns-4 .wp-block-column {
            width: 25%;
        }
    }
}
