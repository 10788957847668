@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-quote {
        border-left: 5px solid var(--color-grey);
        padding: 10px;
        margin-bottom: var(--gutter-vertical);
        text-align: left;

        > * {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.has-text-align-right {
            text-align: left;
        }

        &.has-text-align-left {
            border-right: 0;
            text-align: left;
        }

        &.has-text-align-center {
            border-right: 0;
            text-align: left;
        }

        p {
            font-weight: bold;
            font-style: italic;

            &::before {
                content: "“";
            }

            &::after {
                content: "”";
            }
        }
    }

    @include breakpoint-up(desktop) {
        .wp-block-quote {
            &.has-text-align-right {
                border-left: 0;
                border-right: 5px solid var(--color-grey);
                text-align: right;
            }

            &.has-text-align-left {
                border-right: 0;
            }

            &.has-text-align-center {
                border-right: 5px solid var(--color-grey);
                text-align: center;
            }
        }
    }
}
