@import "styles/mixins";

@include wrapperRoot {
    .header {
        position: fixed;
        height: var(--header-height);
        left: 0;
        right: 0;
        top: 0;
        z-index: 10;
        border-bottom: 1px solid #00550070;
        background-color: var(--color-white);

        header {
            position: relative;
            height: var(--header-height);
            max-width: var(--max-width);
            margin: auto;
            background-color: var(--color-white);
            padding: 0 var(--gutter-horizontal);

            .headerRight {
                position: absolute;
                display: flex;
                right: 60px;
                top: 0;
                height: var(--header-height);
                flex-direction: column;
                align-items: flex-end;
                flex: 0 0 80px;
                justify-content: space-around;
            }
        }

        .logo {
            display: inline-block;
            height: 70px;
            vertical-align: top;
            padding: 5px 0;
        }

        p {
            display: inline-block;
            vertical-align: top;
            line-height: 1em;
            margin: 14px 0 0 10px;
            color: var(--color-primary-green);
            font-weight: bold;
            font-size: 2.2rem;
            font-style: italic;
            width: 170px;
        }

        &.primaryCover {
            color: var(--color-white);
            background-color: rgba(0, 0, 0, 0.5);
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);

            p {
                color: var(--color-white);
            }

            header {
                background-color: transparent;
            }
        }
    }

    @include breakpoint-up(tablet) {
        .header {
            order: 0;
            align-self: stretch;
            flex: 0 0 var(--header-height);
            height: auto;

            header {
                height: var(--header-height);
            }

            p {
                font-size: 2.8rem;
                width: calc(100% - 220px);
                padding-top: 13px;
            }
        }
    }

    @include breakpoint-up(desktop) {
        .header {
            position: relative;

            header {
                .headerRight {
                    height: 80px;
                    right: var(--gutter-horizontal);
                }
            }

            .logo {
                position: relative;
                top: 0;
                height: 80px;
                padding: 5px 0;
            }

            p {
                font-size: 3.7rem;
                padding-top: 8px;
            }
        }
    }

    @include breakpoint-up(widescreen) {
        .header {
            header {
                &::before,
                &::after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 11px;
                    content: " ";
                }

                &::before {
                    left: 0;
                }

                &::after {
                    right: 0;
                    transform: scaleX(-1);
                }
            }
        }
    }

    @include touch-enabled() {
        .header {
            header {
                .headerRight {
                    right: 60px;
                }
            }
        }
    }
}
