@import "../../../styles/mixins/";

%extend_1 {
    max-width: unset;
    width: 100%;
    vertical-align: middle;
}

@include wrapperRoot {
    .wp-block-media-text {
        display: block;

        .wp-block-media-text__content {
            padding: 0;
        }

        .wp-block-media-text__media {
            margin-bottom: var(--gutter-vertical);
            padding: 0;

            img {
                display: block;
                width: 100%;
                height: auto;
                border-radius: 3px;
            }
        }

        > figure {
            > img {
                @extend %extend_1;
            }

            > video {
                @extend %extend_1;
            }
        }
    }

    @include breakpoint-up(mobile-landscape) {
        .wp-block-media-text {
            direction: ltr;
            display: grid;
            grid-template-columns: 40% 1fr !important; // wp-editor
            grid-template-rows: auto !important; // wp-editor

            .wp-block-media-text__content {
                align-self: center;
                direction: ltr;
                word-break: break-word;
                grid-column: 2;
                grid-row: 1;
                padding: 0 0 0 var(--gutter-horizontal);
                margin-bottom: var(--gutter-vertical);
            }

            .wp-block-media-text__media {
                align-self: center;
                grid-column: 1;
                grid-row: 1;
                padding: 0 var(--gutter-horizontal) 0 0;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &.has-media-on-the-right {
                grid-template-columns: 1fr 40% !important; // wp-editor

                .wp-block-media-text__media {
                    grid-column: 2;
                    grid-row: 1;
                    padding: 0 0 0 var(--gutter-horizontal);
                }

                .wp-block-media-text__content {
                    grid-column: 1;
                    grid-row: 1;
                    padding: 0 var(--gutter-horizontal) 0 0;
                }
            }

            &.is-vertically-aligned-top {
                .wp-block-media-text__content {
                    align-self: start;
                }

                .wp-block-media-text__media {
                    align-self: start;
                }
            }

            &.is-vertically-aligned-center {
                .wp-block-media-text__content {
                    align-self: center;
                }

                .wp-block-media-text__media {
                    align-self: center;
                }
            }

            &.is-vertically-aligned-bottom {
                .wp-block-media-text__content {
                    align-self: end;
                }

                .wp-block-media-text__media {
                    align-self: end;
                }
            }

            &.is-image-fill figure.wp-block-media-text__media {
                height: 100%;
                min-height: 250px;
                background-size: cover;

                > img {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    border: 0;
                }
            }
        }
    }

    @include breakpoint-up(desktop) {
        .wp-block-media-text {
            &.alignleft {
                float: none;
                max-width: 100%;
                margin-right: 0;
            }

            &.alignright {
                float: none;
                max-width: 100%;
                margin-left: 0;
            }

            &.aligncenter {
                float: none;
                max-width: 100%;
                margin-right: 0;
                margin-left: 0;
            }

            &.alignwide {
                float: none;
                max-width: 100%;
                margin-right: 0;
                margin-left: 0;
            }

            &.alignfull {
                float: none;
                max-width: 100%;
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
}
