@import "./wordpress";
@import "./normalise";
@import "./mixins/";
@import "./themes";
@import "./reboot";
@import "./scrollbars";

body {
    &.loading {
        &,
        & * {
            cursor: progress;
        }
    }
}
