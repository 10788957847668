@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-table {
        table {
            display: table;
            width: 100%;
            margin-bottom: var(--gutter-vertical);
        }

        figcaption {
            margin-top: calc((var(--gutter-vertical) / 2) * -1);
            margin-bottom: var(--gutter-vertical);
            font-style: italic;
            text-align: center;
        }

        .has-fixed-layout {
            table-layout: fixed;
            width: 100%;

            td {
                word-break: break-word;
            }

            th {
                word-break: break-word;
            }
        }

        .has-subtle-light-gray-background-color {
            background-color: #f3f4f5;
        }

        .has-subtle-pale-green-background-color {
            background-color: #e9fbe5;
        }

        .has-subtle-pale-blue-background-color {
            background-color: #e7f5fe;
        }

        .has-subtle-pale-pink-background-color {
            background-color: #fcf0ef;
        }

        &.is-style-stripes {
            background-color: transparent;

            tbody tr:nth-child(odd) {
                background-color: #f3f4f5;
            }

            &.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
                background-color: #f3f4f5;
            }

            &.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
                background-color: #e9fbe5;
            }

            &.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
                background-color: #e7f5fe;
            }

            &.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
                background-color: #fcf0ef;
            }
        }
    }

    @include breakpoint-up(tablet) {
        .wp-block-table {
            clear: both;
        }
    }
}
