@import "../../mixins/";

@include wrapperRoot {
    --font-size-header: 22px !important;
    --font-size-large: 20px !important;
    --font-size-medium: 16px !important;
    --font-size-primary: 14px !important;
    --font-size-small: 12px !important;
    --font-size-x-small: 10px !important;
    --h1-size: 24px !important;
    --h2-size: 19px !important;
    --h3-size: 16px !important;
    --h4-size: 14px !important;
    --input-font-size: 16px !important;
    --line-height-h1: 24px !important;
    --line-height-h2: 19px !important;
    --line-height-h3: 16px !important;
    --line-height-h4: 14px !important;
    --line-height-primary: 16px !important;

    @include breakpoint-up(tablet) {
        --font-size-header: 26px !important;
        --h1-size: 32px !important;
        --h2-size: 24px !important;
        --h3-size: 20px !important;
        --h4-size: 16px !important;
        --line-height-h1: 32px !important;
        --line-height-h2: 24px !important;
        --line-height-h3: 20px !important;
        --line-height-h4: 16px !important;
    }

    /*** iPhone and iOS Form Input Zoom Fixes ***/
    /* Fix Input Zoom on devices older than iPhone 5: */
    @media screen and (device-aspect-ratio: 2/3) {
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"] {
            font-size: var(--input-font-size);
        }
    }

    /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
    @media screen and (device-aspect-ratio: 40/71) {
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"] {
            font-size: var(--input-font-size);
        }
    }

    /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
    @media screen and (device-aspect-ratio: 375/667) {
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="tel"],
        input[type="url"] {
            font-size: var(--input-font-size);
        }
    }

    /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
    @media screen and (device-aspect-ratio: 9/16) {
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="tel"],
        input[type="url"] {
            font-size: var(--input-font-size);
        }
    }
}
