@import "../mixins/";

@include wrapperRoot {
    /**
     * Blend modes
     */

    .blend-mode-exclusion {
        mix-blend-mode: exclusion;
    }

    .blend-mode-hue {
        mix-blend-mode: hue;
    }

    .blend-mode-saturation {
        mix-blend-mode: saturation;
    }

    .blend-mode-color {
        mix-blend-mode: color;
    }

    .blend-mode-luminosity {
        mix-blend-mode: luminosity;
    }

    /**
     * Colours
     */

    .has-pale-pink-color {
        color: #f78da7;
    }

    .has-vivid-red-color {
        color: #cf2e2e;
    }

    .has-luminous-vivid-orange-color {
        color: #ff6900;
    }

    .has-luminous-vivid-amber-color {
        color: #fcb900;
    }

    .has-light-green-cyan-color {
        color: #7bdcb5;
    }

    .has-vivid-green-cyan-color {
        color: #00d084;
    }

    .has-pale-cyan-blue-color {
        color: #8ed1fc;
    }

    .has-vivid-cyan-blue-color {
        color: #0693e3;
    }

    .has-vivid-purple-color {
        color: #9b51e0;
    }

    .has-white-color {
        color: #fff;
    }

    .has-very-light-gray-color {
        color: #eee;
    }

    .has-cyan-bluish-gray-color {
        color: #abb8c3;
    }

    .has-very-dark-gray-color {
        color: #313131;
    }

    .has-black-color {
        color: #000;
    }

    /**
     * Background colours
     */

    .has-pale-pink-background-color {
        background-color: #f78da7;
    }

    .has-vivid-red-background-color {
        background-color: #cf2e2e;
    }

    .has-luminous-vivid-orange-background-color {
        background-color: #ff6900;
    }

    .has-luminous-vivid-amber-background-color {
        background-color: #fcb900;
    }

    .has-light-green-cyan-background-color {
        background-color: #7bdcb5;
    }

    .has-vivid-green-cyan-background-color {
        background-color: #00d084;
    }

    .has-pale-cyan-blue-background-color {
        background-color: #8ed1fc;
    }

    .has-vivid-cyan-blue-background-color {
        background-color: #0693e3;
    }

    .has-vivid-purple-background-color {
        background-color: #9b51e0;
    }

    .has-white-background-color {
        background-color: #fff;
    }

    .has-very-light-gray-background-color {
        background-color: #eee;
    }

    .has-cyan-bluish-gray-background-color {
        background-color: #abb8c3;
    }

    .has-very-dark-gray-background-color {
        background-color: #313131;
    }

    .has-black-background-color {
        background-color: #000;
    }

    /**
     * Background gradients
     */

    .has-vivid-cyan-blue-to-vivid-purple-gradient-background {
        background: linear-gradient(135deg, #0693e3, #9b51e0);
    }

    .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
        background: linear-gradient(135deg, #00d084, #0693e3);
    }

    .has-light-green-cyan-to-vivid-green-cyan-gradient-background {
        background: linear-gradient(135deg, #7adcb4, #00d082);
    }

    .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
        background: linear-gradient(135deg, #fcb900, #ff6900);
    }

    .has-luminous-vivid-orange-to-vivid-red-gradient-background {
        background: linear-gradient(135deg, #ff6900, #cf2e2e);
    }

    .has-very-light-gray-to-cyan-bluish-gray-gradient-background {
        background: linear-gradient(135deg, #eee, #a9b8c3);
    }

    .has-cool-to-warm-spectrum-gradient-background {
        background: linear-gradient(
            135deg,
            #4aeadc,
            #9778d1 20%,
            #cf2aba 40%,
            #ee2c82 60%,
            #fb6962 80%,
            #fef84c
        );
    }

    .has-blush-light-purple-gradient-background {
        background: linear-gradient(135deg, #ffceec, #9896f0);
    }

    .has-blush-bordeaux-gradient-background {
        background: linear-gradient(135deg, #fecda5, #fe2d2d 50%, #6b003e);
    }

    .has-purple-crush-gradient-background {
        background: linear-gradient(135deg, #34e2e4, #4721fb 50%, #ab1dfe);
    }

    .has-luminous-dusk-gradient-background {
        background: linear-gradient(135deg, #ffcb70, #c751c0 50%, #4158d0);
    }

    .has-hazy-dawn-gradient-background {
        background: linear-gradient(135deg, #faaca8, #dad0ec);
    }

    .has-pale-ocean-gradient-background {
        background: linear-gradient(135deg, #fff5cb, #b6e3d4 50%, #33a7b5);
    }

    .has-electric-grass-gradient-background {
        background: linear-gradient(135deg, #caf880, #71ce7e);
    }

    .has-subdued-olive-gradient-background {
        background: linear-gradient(135deg, #fafae1, #67a671);
    }

    .has-atomic-cream-gradient-background {
        background: linear-gradient(135deg, #fdd79a, #004a59);
    }

    .has-nightshade-gradient-background {
        background: linear-gradient(135deg, #330968, #31cdcf);
    }

    .has-midnight-gradient-background {
        background: linear-gradient(135deg, #020381, #2874fc);
    }
}
