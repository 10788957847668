@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-image {
        position: relative;
        margin-bottom: var(--gutter-vertical);

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            margin-bottom: var(--gutter-vertical);
        }

        figure {
            margin: 0;
        }

        figcaption {
            position: relative !important; //wp-editor
            background: transparent !important; //wp-editor
            display: block !important; //wp-editor
            color: var(--color-black) !important; //wp-editor
            font-size: var(--font-size-medium) !important; //wp-editor
            text-align: center;
            margin-bottom: var(--gutter-vertical);

            a {
                text-decoration: none;
            }
        }

        &.is-resized {
            margin-left: auto;
            margin-right: auto;
        }

        &.aligncenter {
            text-align: center;
            display: table;

            > figcaption {
                display: table-caption;
                caption-side: bottom;
            }
        }

        .aligncenter {
            display: table;
            margin-left: auto;
            margin-right: auto;

            > figcaption {
                display: table-caption;
                caption-side: bottom;
            }
        }

        &.alignleft {
            display: table;

            > figcaption {
                display: table-caption;
                caption-side: bottom;
            }
        }

        .alignleft {
            display: table;
            float: left;
            margin: 0.5em 1em 0.5em 0;

            > figcaption {
                display: table-caption;
                caption-side: bottom;
            }
        }

        &.alignright {
            display: table;

            > figcaption {
                display: table-caption;
                caption-side: bottom;
            }
        }

        .alignright {
            display: table;
            float: right;
            margin: 0.5em 0 0.5em 1em;

            > figcaption {
                display: table-caption;
                caption-side: bottom;
            }
        }

        &.alignfull img {
            height: auto;
            width: 100%;
        }

        &.alignwide img {
            height: auto;
            width: 100%;
        }

        &:not(.is-style-rounded) {
            > a {
                border-radius: inherit;
            }

            img {
                border-radius: inherit;
            }
        }

        &.is-style-circle-mask img {
            border-radius: 9999px;
        }

        &.is-style-rounded img {
            border-radius: 9999px;
        }

        .is-style-rounded img {
            border-radius: 9999px;
        }
    }

    @supports ((-webkit-mask-image: none) or (mask-image: none)) or (-webkit-mask-image: none) {
        .wp-block-image.is-style-circle-mask img {
            -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
            mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
            mask-mode: alpha;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: contain;
            mask-size: contain;
            -webkit-mask-position: center;
            mask-position: center;
            border-radius: 0;
        }
    }

    @include breakpoint-up(desktop) {
        .wp-block-image {
            .alignleft {
                margin-right: 0;
            }

            .alignright {
                margin-left: 0;
            }

            .aligncenter figcaption {
                text-align: center;
            }
        }
    }
}
