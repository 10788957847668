@import "../../../styles/mixins/";

/**
 * STYLES
 */

%link {
    font-weight: bold;
    text-decoration: none;
    color: var(--color-black);

    &::before {
        display: inline-block;
        margin-right: 5px;
        font-family: "FontAwesome";
        font-weight: 400;
        content: "\f56d";
        vertical-align: text-top;
        color: var(--color-orange);
        vertical-align: baseline;
    }

    &:hover {
        color: var(--color-grey);
    }
}

%link-dark {
    color: var(--color-white);

    &:hover {
        color: var(--color-lightgrey);
    }
}

%button {
    margin-left: 8px;
}

@include wrapperRoot {
    /**
 * CLASSES
 */

    .wp-block-file {
        margin-bottom: var(--gutter-vertical);

        a {
            &:not(.wp-block-file__button) {
                @extend %link;
            }

            &.wp-block-file__button {
                @extend %button;
            }
        }

        .wp-block-file__textlink div {
            @extend %link;

            &::before {
                background-color: var(--color-orange);
            }
        }

        .wp-block-file__button {
            @extend %button;
        }
    }

    .primary-cover {
        .wp-block-file {
            a {
                &:not(.wp-block-file__button) {
                    @extend %link-dark;
                }
            }
        }
    }
}
