@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-embed {
        margin: 0 0 1em;
        overflow-wrap: break-word;

        figcaption {
            color: #6d6d6d;
            font-size: var(--font-size-primary);
            margin-bottom: 0;
            margin-top: var(--gutter-vertical);
            margin-bottom: var(--gutter-vertical);
        }

        &.alignleft {
            max-width: 360px;
            width: 100%;

            .wp-block-embed__wrapper {
                min-width: 280px;
            }
        }

        &.alignright {
            max-width: 360px;
            width: 100%;

            .wp-block-embed__wrapper {
                min-width: 280px;
            }
        }

        iframe {
            max-width: 100%;
        }

        &__wrapper {
            position: relative;
        }
    }

    .wp-embed-responsive {
        .wp-has-aspect-ratio {
            iframe {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }

            .wp-block-embed__wrapper:before {
                content: "";
                display: block;
                padding-top: 50%;
            }
        }

        .wp-embed-aspect-21-9 .wp-block-embed__wrapper:before {
            padding-top: 42.85%;
        }

        .wp-embed-aspect-18-9 .wp-block-embed__wrapper:before {
            padding-top: 50%;
        }

        .wp-embed-aspect-16-9 .wp-block-embed__wrapper:before {
            padding-top: 56.25%;
        }

        .wp-embed-aspect-4-3 .wp-block-embed__wrapper:before {
            padding-top: 75%;
        }

        .wp-embed-aspect-1-1 .wp-block-embed__wrapper:before {
            padding-top: 100%;
        }

        .wp-embed-aspect-9-16 .wp-block-embed__wrapper:before {
            padding-top: 177.77%;
        }

        .wp-embed-aspect-1-2 .wp-block-embed__wrapper:before {
            padding-top: 200%;
        }
    }
}
