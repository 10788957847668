@import "../../../styles/mixins/";

@include wrapperRoot {
    .wp-block-columns {
        display: block;
        margin-bottom: 0;
    }

    @include breakpoint-up(tablet) {
        .wp-block-columns {
            display: flex;
            flex-wrap: nowrap;
            gap: var(--gutter-vertical) var(--gutter-horizontal);
            margin-bottom: var(--gutter-vertical);

            &.has-background {
                padding: 20px 30px;
            }

            &.are-vertically-aligned-top {
                align-items: flex-start;
            }

            &.are-vertically-aligned-center {
                align-items: center;
            }

            &.are-vertically-aligned-bottom {
                align-items: flex-end;
            }
            &.is-style-dynamics-columns-overlap {
                justify-content: space-around;
            }
        }

        .wp-block-column {
            flex-grow: 0;
            min-width: 0;
            word-break: break-word;
            overflow-wrap: break-word;
            flex-basis: 100%;
            flex-basis: 0;
            flex-grow: 1;

            &.is-vertically-aligned-top {
                align-self: flex-start;
                width: 100%;
            }

            &.is-vertically-aligned-center {
                align-self: center;
                width: 100%;
            }

            &.is-vertically-aligned-bottom {
                align-self: flex-end;
                width: 100%;
            }

            .max-width {
                padding: 0;
            }

            // p:last-of-type {
            //     margin-bottom: 0;
            // }
        }
    }
}
