@import "../../../styles/mixins/";

%extend_1 {
    text-decoration: none;
    border-bottom: 0;
    box-shadow: none;
}

@include wrapperRoot {
    .wp-block-social-links {
        display: block;
        margin: 0 0 calc(var(--gutter-vertical) - 5px);

        &.is-style-logos-only {
            .wp-social-link {
                background: none;
                padding: 4px;

                svg {
                    width: 28px;
                    height: 28px;
                }
            }

            .wp-social-link-amazon {
                color: #f90;
            }

            .wp-social-link-bandcamp {
                color: #1ea0c3;
            }

            .wp-social-link-behance {
                color: #0757fe;
            }

            .wp-social-link-codepen {
                color: #24292d;
            }

            .wp-social-link-deviantart {
                color: #02e49b;
            }

            .wp-social-link-dribbble {
                color: #e94c89;
            }

            .wp-social-link-dropbox {
                color: #21a1f3;
            }

            .wp-social-link-etsy {
                color: #ff5600;
            }

            .wp-social-link-facebook {
                color: #21a1f3;
            }

            .wp-social-link-fivehundredpx {
                color: black;
            }

            .wp-social-link-flickr {
                color: #0461dd;
            }

            .wp-social-link-foursquare {
                color: #e65678;
            }

            .wp-social-link-github {
                color: #24292d;
            }

            .wp-social-link-goodreads {
                color: #382110;
            }

            .wp-social-link-google {
                color: #ea4434;
            }

            .wp-social-link-instagram {
                color: #f00075;
            }

            .wp-social-link-lastfm {
                color: #e21b24;
            }

            .wp-social-link-linkedin {
                color: #0577b5;
            }

            .wp-social-link-mastodon {
                color: #3499cd;
            }

            .wp-social-link-medium {
                color: #02ab6c;
            }

            .wp-social-link-meetup {
                color: #f6405f;
            }

            .wp-social-link-patreon {
                color: #ff424d;
            }

            .wp-social-link-pinterest {
                color: #e60122;
            }

            .wp-social-link-pocket {
                color: #ef4155;
            }

            .wp-social-link-reddit {
                color: #fe4500;
            }

            .wp-social-link-skype {
                color: #0478d7;
            }

            .wp-social-link-snapchat {
                color: white;
                stroke: black;
            }

            .wp-social-link-soundcloud {
                color: #ff5600;
            }

            .wp-social-link-spotify {
                color: #1bd760;
            }

            .wp-social-link-telegram {
                color: #011835;
            }

            .wp-social-link-tiktok {
                color: #011835;
            }

            .wp-social-link-tumblr {
                color: #011835;
            }

            .wp-social-link-twitch {
                color: #6440a4;
            }

            .wp-social-link-twitter {
                color: #21a1f3;
            }

            .wp-social-link-vimeo {
                color: #1eb7ea;
            }

            .wp-social-link-vk {
                color: #4680c2;
            }

            .wp-social-link-wordpress {
                color: #3499cd;
            }

            .wp-social-link-yelp {
                color: #d32422;
            }

            .wp-social-link-youtube {
                color: #ff0100;
            }
        }

        .wp-social-link a {
            @extend %extend_1;
            &:hover {
                @extend %extend_1;
            }
        }

        &:not(.is-style-logos-only) {
            .wp-social-link {
                background-color: #f0f0f0;
                color: #444;
            }

            .wp-social-link-amazon {
                background-color: #f90;
                color: white;
            }

            .wp-social-link-bandcamp {
                background-color: #1ea0c3;
                color: white;
            }

            .wp-social-link-behance {
                background-color: #0757fe;
                color: white;
            }

            .wp-social-link-codepen {
                background-color: #24292d;
                color: white;
            }

            .wp-social-link-deviantart {
                background-color: #02e49b;
                color: white;
            }

            .wp-social-link-dribbble {
                background-color: #e94c89;
                color: white;
            }

            .wp-social-link-dropbox {
                background-color: #21a1f3;
                color: white;
            }

            .wp-social-link-etsy {
                background-color: #ff5600;
                color: white;
            }

            .wp-social-link-facebook {
                background-color: #21a1f3;
                color: white;
            }

            .wp-social-link-fivehundredpx {
                background-color: black;
                color: white;
            }

            .wp-social-link-flickr {
                background-color: #0461dd;
                color: white;
            }

            .wp-social-link-foursquare {
                background-color: #e65678;
                color: white;
            }

            .wp-social-link-github {
                background-color: #24292d;
                color: white;
            }

            .wp-social-link-goodreads {
                background-color: #eceadd;
                color: #382110;
            }

            .wp-social-link-google {
                background-color: #ea4434;
                color: white;
            }

            .wp-social-link-instagram {
                background-color: #f00075;
                color: white;
            }

            .wp-social-link-lastfm {
                background-color: #e21b24;
                color: white;
            }

            .wp-social-link-linkedin {
                background-color: #0577b5;
                color: white;
            }

            .wp-social-link-mastodon {
                background-color: #3499cd;
                color: white;
            }

            .wp-social-link-medium {
                background-color: #02ab6c;
                color: white;
            }

            .wp-social-link-meetup {
                background-color: #f6405f;
                color: white;
            }

            .wp-social-link-patreon {
                background-color: #ff424d;
                color: white;
            }

            .wp-social-link-pinterest {
                background-color: #e60122;
                color: white;
            }

            .wp-social-link-pocket {
                background-color: #ef4155;
                color: white;
            }

            .wp-social-link-reddit {
                background-color: #fe4500;
                color: white;
            }

            .wp-social-link-skype {
                background-color: #0478d7;
                color: white;
            }

            .wp-social-link-snapchat {
                background-color: #fefc00;
                color: white;
                stroke: black;
            }

            .wp-social-link-soundcloud {
                background-color: #ff5600;
                color: white;
            }

            .wp-social-link-spotify {
                background-color: #1bd760;
                color: white;
            }

            .wp-social-link-telegram {
                background-color: #2aabee;
                color: white;
            }

            .wp-social-link-tiktok {
                background-color: #000;
                color: white;
            }

            .wp-social-link-tumblr {
                background-color: #011835;
                color: white;
            }

            .wp-social-link-twitch {
                background-color: #6440a4;
                color: white;
            }

            .wp-social-link-twitter {
                background-color: #21a1f3;
                color: white;
            }

            .wp-social-link-vimeo {
                background-color: #1eb7ea;
                color: white;
            }

            .wp-social-link-vk {
                background-color: #4680c2;
                color: white;
            }

            .wp-social-link-wordpress {
                background-color: #3499cd;
                color: white;
            }

            .wp-social-link-yelp {
                background-color: #d32422;
                color: white;
            }

            .wp-social-link-youtube {
                background-color: #ff0100;
                color: white;
            }
        }

        &.is-style-pill-shape .wp-social-link {
            width: auto;

            a {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }

    .wp-social-link {
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        margin-right: 5px;
        margin-bottom: 5px;
        transition: transform #{$x-fast}s ease;

        a {
            padding: 6px;
            display: block;
            line-height: 0;
            transition: transform #{$x-fast}s ease;
            color: currentColor;
            fill: currentColor;

            &:active {
                color: currentColor;
                fill: currentColor;
            }

            &:hover {
                color: currentColor;
                fill: currentColor;
            }

            &:visited {
                color: currentColor;
                fill: currentColor;
            }
        }

        svg {
            color: currentColor;
            fill: currentColor;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    @media (prefers-reduced-motion: reduce) {
        .wp-social-link {
            transition-duration: 0s;
        }
    }
}
